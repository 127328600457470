import { useState } from "react";

const Base64ToFileIndexPage = () => {

    const [base64, setBase64] = useState('');
    const [result, setResult] = useState('');
    const [error, setError] = useState('');

    const onConvert = () => {
        try {
            if (base64) {
                const binaryData = atob(base64);
                const arrayBuffer = new ArrayBuffer(binaryData.length);
                const uInt8Array = new Uint8Array(arrayBuffer);
                for (let i = 0; i < binaryData.length; i++) {
                    uInt8Array[i] = binaryData.charCodeAt(i);
                }
                const blob = new Blob([uInt8Array]);
                const file = new File([blob], 'converted');
                const url = URL.createObjectURL(file);
                setResult(url);
            }
        } catch (err) {
            setError(err);
        }
    }

    return (
        <div className="flex flex-col">
            <div className="flex flex-col mx-auto border border-gray-400 rounded-xl w-full sm:w-2/3 bg-gray-100 mt-20 shadow-lg p-5 sm:p-10">
                <div className="font-bold">Enter Base 64</div>
                <textarea rows={10} cols={100} value={base64} onChange={(e) => setBase64(e.target.value)} className="bg-slate-700 text-white p-5 rounded-lg my-4 overflow-x-hidden" />
                <div className="flex justify-between">
                    <div
                        className="border border-slate-500 px-4 py-2 rounded-lg bg-slate-700 hover:bg-slate-500 cursor-pointer text-white "
                        onClick={onConvert}>
                        Convert
                    </div>
                    <div></div>
                </div>
            </div>
            {error !== null ? <div className="flex flex-col mx-auto border-gray-400 rounded bg-gray-100 mt-20 shadow-lg ">
                <div><img className="rounded-lg" src={result} /></div>
            </div> :
                <div>Failed to convert to Base64</div>
            }

        </div>
    )
}

export default Base64ToFileIndexPage;