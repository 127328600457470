import { useRef, useState } from "react";
import { Helmet } from 'react-helmet';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const FileToBase64IndexPage = () => {

    const [dragging, setDragging] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [base64, setBase64] = useState(null);
    const [isCopied, setIsCopied] = useState(false);

    const inputFile = useRef<HTMLInputElement | null>(null);

    const onUploadButtonClick = (e) => {
        e.preventDefault();
        inputFile.current?.click();
    }

    const onFileChange = (e) => {
        e.preventDefault();
        const files = e.target.files;
        var fileArr: File[] = [];
        for (let i = 0; i < files.length; i++) {
            fileArr[i] = files[i];
        }
        setSelectedFiles(fileArr);
    }

    const onDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setDragging(true);
    }

    const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setDragging(true);
    }

    const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        setDragging(false);
    }

    const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setDragging(false);

        var droppedFiles = e.dataTransfer.files;
        var selectedFiles = inputFile.current.files;

        if (selectedFiles) {
            inputFile.current.files = droppedFiles;
            onHandleFiles(inputFile.current.files);
        }
    }

    const onDrag = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setDragging(false)

    }

    const onHandleFiles = (files: FileList) => {
        const fileList: File[] = [];
        for (let i = 0; i < files.length; i++) {
            var file: File = files[i];
            fileList[i] = file;
            console.log('uploaded file: ', file.name);
        }
        setSelectedFiles(fileList);
    }

    const onConvert = (index: number) => {
        const reader = new FileReader();

        reader.onload = () => {
            setBase64(reader.result.toString().split(',')[1]);
        };

        reader.readAsDataURL(selectedFiles[index]);
    };

    const onCopyToClipboard = () => {
        navigator.clipboard.writeText(base64).then(() => {
            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
            }, 2000);
        })
    }


    return (
        <div className="flex flex-col">
            <div
                className={`flex flex-col mx-auto border ${dragging ? 'border-dashed' : ''} border-gray-400 rounded-xl w-full sm:w-2/3 bg-gray-100 mt-20 shadow-lg ${dragging ? 'bg-green-300' : ''}`}
                onChange={onFileChange}
                onDragEnter={(e) => onDragEnter(e)}
                onDragOver={(e) => onDragOver(e)}
                onDragLeave={(e) => onDragLeave(e)}
                onDrop={(e) => onDrop(e)}
            >
                <div className="p-10 flex flex-col">
                    <div className="mx-auto"><button onClick={onUploadButtonClick} className="border px-10 py-2 border-slate-700 rounded-lg bg-slate-700 font-bold text-white hover:bg-slate-500">Choose Files</button></div>
                    <input type='file' id='file' multiple ref={inputFile} onChange={onFileChange} className="hidden" />
                    <div className="mx-auto"><div className="underline font-bold my-5 mx-auto">OR</div></div>
                    <div className="mx-auto mb-5 font-bold text-slave-700 ">You can also Drag and drop your files here!</div>
                    <div className="mx-auto flex flex-col">
                        {selectedFiles && <div className="font-bold">Selected Files:</div>}
                        {selectedFiles && selectedFiles.map((file: File, index) =>
                            <div key={index} className="flex justify-between items-center gap-6 border border-collapse border-t-blue rounded bg-white shadow p-1.5 px-6 my-1">
                                <div>{index + 1}. File: {file.name} ({(file.size / 1000000).toFixed(2)}MB)</div>
                                <div onClick={() => onConvert(index)} className="border border-slate-500 px-4 py-2 rounded-lg bg-slate-700 hover:bg-slate-500 cursor-pointer text-white">Convert</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="flex flex-col mx-auto border border-gray-400 rounded-xl w-full sm:w-2/3  bg-gray-100 mt-20 shadow-lg">
                <div className="p-10">
                    <div className="flex justify-between items-center mb-5">
                        <div className="font-bold">Base64:</div>
                        {base64 && !isCopied && <div className="border px-4 py-2 text-white bg-slate-700 rounded-lg hover:bg-slate-500 cursor-pointer" onClick={onCopyToClipboard}>Copy</div>}
                        {base64 && isCopied && <div className="flex justify-between items-center gap-2 px-4 py-2 text-white bg-green-600 rounded-lg"><CheckCircleOutlineIcon /> Copied</div>}
                    </div>
                    <div className="h-80 overflow-y-scroll overflow-x-hidden break-words">{base64}</div>
                </div>
            </div>
        </div>
    )
}

export default FileToBase64IndexPage;