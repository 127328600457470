import React from 'react';
import logo from './logo.svg';
import './App.css';

import fileToBase64ExplainImage from '../src/assets/images/file_to_base_64_explain.png';
import fileToBase64ResultExplainImage from '../src/assets/images/file_to_base_64_result_explain.png';

function App() {
  return (
    <div className='flex flex-col mx-auto p-5'>
      <div className='mx-auto'>
        <div className='font-bold mb-2 text-3xl'>File to Base64</div>
        <div><span className='font-bold'>Step 1.</span> Select a file or files that you would like to convert.</div>
        <div><span className='font-bold'>Step 2.</span> Click the convert button.</div>
        <div className='mb-4'>
          <img className='' src={fileToBase64ExplainImage} style={{ maxWidth: '80%', maxHeight: '80%' }} alt='file_to_base64' />
        </div>
        <div>Result will appear once you click the convert button that you have clicked.</div>
        <div>
          <img className='' src={fileToBase64ResultExplainImage} style={{ maxWidth: '80%', maxHeight: '80%' }} alt='file_to_base64' />
        </div>
      </div>
    </div>

  );
}

export default App;
