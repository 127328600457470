import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import SharedNavBar from './components/shared-navbar';
import SharedFooter from './components/shared-footer';
import FileToBase64IndexPage from './file-to-base64';
import Base64ToFileIndexPage from './base64-to-file';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <div className='h-screen bg-stone-50 text-slate-700'>
        <SharedNavBar />
          <div className='pt-28 bg-stone-50'>
            <Routes>
              <Route path="/" element={<App />} />
              <Route path="/tobase64" element={<FileToBase64IndexPage />} />
              <Route path="/tofile" element={<Base64ToFileIndexPage />} />
            </Routes>
          </div>
        <SharedFooter />
      </div>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
